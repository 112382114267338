// import icons from 'url:../../img/icons.svg';

export default class View {
    _data;

    /**
     * This will be common for all classes in the next lectures!
     * @param {*} data
     */
    render(data, renderError = true) {
        if (renderError && (!data || (Array.isArray(data) && data.length === 0))) return this.renderError();

        this._data = data;
        const markup = this._generateMarkup();
        this.clear();

        // insert the new element
        this._parentElement.insertAdjacentHTML('afterbegin', markup);
    }

    /**
     * Only update text and attributes in the DOM without having to re-render the entire
     * view like in the render() method.
     * @param {*} data
     * @returns
     */
    update(data) {
        this._data = data;
        const newMarkup = this._generateMarkup();

        /* Converts the string into real DOM object to be compared (virtual DOM).
         * This DOM object does not live in the page but lives in our memory.
         */
        const newDOM = document.createRange().createContextualFragment(newMarkup);
        const newElements = Array.from(newDOM.querySelectorAll('*'));
        const curElements = Array.from(this._parentElement.querySelectorAll('*'));

        // compare the DOM elements
        newElements.forEach((newEl, i) => {
            const curEl = curElements[i];

            // updates text
            if (!newEl.isEqualNode(curEl) && newEl.firstChild?.nodeValue.trim() !== '') {
                curEl.textContent = newEl.textContent;
            }

            // updates attributes
            if (!newEl.isEqualNode(curEl)) {
                Array.from(newEl.attributes).forEach((attr) => curEl?.setAttribute(attr.name, attr.value));
            }
        });
    }

    /**
     * Clean the container recipe before insert the new element.
     */
    clear() {
        this._parentElement.innerHTML = '';
    }

    renderSpinner() {
        const markup = `
        	<div class="spinner"></div>
      	`;

        this.clear();
        this._parentElement.insertAdjacentHTML('afterbegin', markup);
    }

    renderError(message = this._errorMessage) {
        const markup = `
        	<div class="error">
        	  <div>
        	    <svg>
        	      <use href="#icon-alert-triangle"></use>
        	    </svg>
        	  </div>
        	  <p>${message}</p>
        	</div>
      	`;

        this.clear();
        this._parentElement.insertAdjacentHTML('afterbegin', markup);
    }
}

import View from './view';

class SubscribeFormView extends View {
    _parentElement = document.querySelector('.subscribe-form');

    constructor() {
        super();
        this._bootstrapForm();
    }

    render(data) {
        this._parentElement = document.querySelector('.subscribe-form');
        super.render(data);
    }

    displayForm() {
        this._parentElement.classList.remove('hidden');
    }

    _generateMarkup() {
        const selectedBook = this._data.selectedBook;
        if (selectedBook) {
            return this[`_${selectedBook.name}Form`]();
        }

        return this._generalForm();
    }

    _generalForm() {
        return `<div data-easymform="GWWkoFQ5FEhOzBLHn8FJGr"></div>`;
    }

    /**
     * Easymailing suscription form.
     */
    _bootstrapForm() {
        (function (w, d, s, i) {
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
            j.async = true;
            j.src =
                'https://assets.easymailing.com/build/trck/universal.js' + '?v' + ~~(new Date().getTime() / 1000000);
            f.parentNode.insertBefore(j, f);
            w.__easymsettings = { domain: i };
        })(window, document, 'script', 'llepaplats.easymailing.com');
    }
}

export default new SubscribeFormView();

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as model from './model.js';
import appView from './views/appView.js';
import subscribeFormView from './views/subscribeFormView.js';

const bootstrapParams = (data) => {
    model.state.language = data?.language || 'en';
    model.state.pathname = data?.pathname;
};

/**
 * Display the real form once it is loaded.
 */
const controlDisplayRealForm = () => {
    subscribeFormView.displayForm();
};

const controlLoadTemplate = async (pathname) => {
    return await model.loadTemplate(pathname);
};

/**
 * Initializes the chat app by adding event listeners to various views
 * and initializing necessary components.
 * @function
 * @async
 * @returns {void}
 */
const init = async () => {
    try {
        // set config after the view is load
        appView.addHandlerLoad(async (data) => {
            bootstrapParams(data);
            const html = await controlLoadTemplate(data.pathname);
            appView.loadTemplate(html);
            subscribeFormView.render({
                language: data?.language,
                selectedBook: model.state.selectedBook,
            });

            controlDisplayRealForm();
        });
    } catch (err) {
        console.error(err.message);
        appView.renderError();
    }
};

init();

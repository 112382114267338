import View from './view.js';

class AppView extends View {
    _parentElement = document.getElementById('app');

    addHandlerLoad(handler) {
        window.addEventListener('load', () => {
            const pathname = window.location.pathname;

            handler({
                language: navigator.language,
                pathname,
            });
        });
    }

    loadTemplate(html) {
        this._parentElement.querySelector('main').innerHTML = html;
    }
}

export default new AppView();

import mainHTML from '../html/main.html';

export const state = {
    language: '',
    selectedBook: null,
};

/**
 * Return the HTML template.
 * @param {string} url
 * @returns
 */
export async function loadTemplate(pathname) {
    const html = getTemplate(pathname);

    const response = await fetch(html);
    return await response.text();
}

function getTemplate(pathname) {
    pathname = removeSlash(pathname);
    return mainHTML;
}

function removeSlash(texto) {
    if (texto.startsWith('/')) return texto.slice(1);
    return texto;
}
